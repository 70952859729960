// import AppCache from './AppCache';

// export const configFileURL = '/siteconfig/AppConfig.json';


 export const API_URL = '/api/';

console.log("API_URL", API_URL)
//   const appsettings = require('../../public/AppConfig.json');
//   const API_URL = appSettings.SmileServiceApiBaseUrl;
//  console.log(API_URL);


const ExecutiveLogin = "ExpenseManager/V1/ExecutiveLogin";

const dashBoardMenuUrlroute = "Service/V1/DashBoardMenu";
// const loginImageUrlroute = "ExpenseManager/V1/LoginImage";
const loginImageUrlroute = "LoginImage/LoginImage";
const changeloginImageUrlroute = "LoginImage/getLoginImage";
const getClientAliasUrlroute = "ProjectInformation/getClientAliasList";
const getProjectServerDrivesTab = "ProjectInformation/GetProjectServerDrives";
const getClientNames = "ProjectInformation/GetClientNames";
const postProjectInformation = "ProjectInformation/SaveProjectInformation";
const getProjectinformation = "ProjectInformation/GetProjectinformation";
const getClients = "ProjectInformation/GetClients";
const getServerProvider = "ProjectInformation/GetServerProvider";
const getProjectinformationDropDown = "ProjectInformation/GetProjectinformationDropDown";
const getDropdown = "ProjectInformation/GetFilteredDropdown";
const getServiceProvider = "ProjectInformation/GetServiceProvider";
const getClientAliasDropDownList = "ProjectInformation/GetClientAliasDropDownList";
const getExecutiveListDropDown = "Contact/GetExecutiveListDropDown";
const postMarkAsAbsent = "Contact/MarkAsAbsent";
const getExecutiveImmediateListDropDown = "Contact/GetExecutiveImmediateListDropDown";


// Login
const getUserPhoto = "ExpenseManager/V1/UserPhotos?Contactid=";
const postaddAttendance = "ExpenseManager/V1/attendance";
const getAttendance = "ExpenseManager/V1/GetAttendance?ContactId=";
const getUserAccessRights = "ExpenseManager/V1/GetUserAccessRights";
const getDashboardMenus = "ExpenseManager/V1/GetDashboardMenus";
const saveCopyAccessRights = "ExpenseManager/V1/SaveCopyAccessRights";


// ClientMaster
const getClientMaster = "OrderAcknowledgement/GetClientMaster";
const saveClientDetails = "OrderAcknowledgement/SaveClientDetails";
const getProjectStatusDropDown = "OrderAcknowledgement/GetProjectStatusDropDown";
const getClientMasterDropDownData = "OrderAcknowledgement/GetClientMasterDropDownData";
const getClientMasterFilterDropDown = "OrderAcknowledgement/GetClientMasterFilterDropDown";
const getTeamAndPrefferedClientsDropdown = "OrderAcknowledgement/GetTeamAndPrefferedClientsDropdown";
const getClientGroupingDropDown = "OrderAcknowledgement/GetClientGroupingDropDown";
const getClientNamesDD = "OrderAcknowledgement/GetClientNamesDropDown";
const getClientNameValidation = "OrderAcknowledgement/GetClientNameValidation";
const getERPCodeValidation = "OrderAcknowledgement/GetERPCodeValidation";
const getProjectNamesDropDown = "Project/GetProjectNames";
const getDesignationName = "Project/GetExecutiveDesignation?ContactId=";
const getDepartmentName = "Project/GetExecutiveDepartment?ContactId=";
const getRoles = "OrderAcknowledgement/GetRoles";
const getClientMasterListingData = "OrderAcknowledgement/GetClientMasterListingData";
const getExecutiveInfo = "OrderAcknowledgement/GetExecutiveInfo";


// ClientInstallationLocations
const getClientInstallationLocationData = "ClientInstallationLocation/GetClientInstallationLocationData";
const getContactTypeIdDropDown = "ClientInstallationLocation/GetContactTypeIdDropDown";
const getAMCDropDownData = "ClientInstallationLocation/GetAMCDropDownData";
const getClassificationDropDown = "ClientInstallationLocation/GetClassificationDropDown";
const getSiteAutocomplete = "ClientInstallationLocation/GetSiteAutocomplete";
const getProjectVersionAutocomplete = "ClientInstallationLocation/GetProjectVersionAutocomplete";
const getClientsDropDown = "ClientInstallationLocation/GetClientsDropDown";
const editClientInstallationLocations = "ClientInstallationLocation/EditClientInstallationLocations";
const editContactMaster = "ClientInstallationLocation/EditContactMaster";
const postClientInstallationLocations = "ClientInstallationLocation/SaveClientInstallationLocations"; 
const postContactMaster = "ClientInstallationLocation/SaveContactMaster"; 
const getContactMasterData = "ClientInstallationLocation/GetContactMasterData";
const getClientInstallationContactMaster = "ClientInstallationLocation/GetClientInstallationContactMaster";
const postbulkSave = "ClientInstallationLocation/BulkSave";
const postContactSite = "ClientInstallationLocation/SaveContactSite";
const getClientInstallationFilterDropDown = "ClientInstallationLocation/GetClientInstallationFilterDropDown";



// Contact Master
const getContactMaster = "ClientInstallationLocation/GetContactMaster";


// Product Master
const getProductMasterListingData = "ClientInstallationLocation/GetProductMasterListingData";
 const getProductMasterAutocomplete = "ClientInstallationLocation/GetProductMasterAutocomplete";
/* const getProductMasterAutocomplete = "OrderAcknowledgement/OAClientsDropDown"; */
const saveProductMaster = "ClientInstallationLocation/SaveProductMasterDetails";
const getProductMasterFilterDropDown = "ClientInstallationLocation/GetProductMasterFilterDropDown";
const getProductNameValidation = "OrderAcknowledgement/GetProductNameValidation";
const getProductMasterEditData = "ClientInstallationLocation/GetProductMasterEditData";


// Api Master
const getApiMasterId = "APIMasters/GetAPIMasterId";
const getAPIMasterListingData = "APIMasters/GetAPIMasterListing";
const saveAPIMaster = "APIMasters/SaveAPIMaster";
const getAPIMasterEditData = "APIMasters/GetAPIMasterEditData";
const checkAPIIdExist = "APIMasters/CheckAPIIdExist";


// Service

const getServiceWeightage = "Service/V1/GetServiceWeightage";
const getTeamName = "Service/V1/GetTeamNameDD";
const getExecutiveName = "Service/V1/GetExecutiveNameDD";
const postServiceWeightage = "Service/V1/SaveServiceWeightage";
const postServiceWeightageAll = "Service/V1/SaveAllServiceWeightage";
const postfilterServiceWeightage = "Service/V1/GetFilteredServiceWeightage";
const getExecutiveNameDepartmentDD = "Service/V1/GetExecutiveNameDepartmentDD";

// ServiceEntry
const getServiceEntryClientName = "Service/V1/GetClientNameDD";
const getServiceEntrySiteName = "Service/V1/GetSiteNameDD";
const getServiceEntryActivity = "Service/V1/GetActivityDD";
const getServiceEntryProject = "Service/V1/GetProjectDD";
const postQuickServiceEntry = "Service/V1/SaveQuickServiceEntry";
const getFilteredServicEntrySectionC = "Service/V1/GetClientDataGrid";
const getActivityDataGrid = "Service/V1/GetActivityDataGrid";
const getFilteredServicEntrySectionD = "Service/V1/GetServiceDataGrid";
const getServiceEntryTotalServiceCount = "Service/V1/GetServiceEntryTotalServiceCount";
const getServiceEntryTotalMinutes = "Service/V1/GetServiceEntryTotalMinutes";
const updateQuickServiceEntry = "Service/V1/UpdateQuickServiceEntry";
const getDefaultIssueId = "Service/V1/GetDefaultIssueId";



// Contact Popover
const getReportingToDropDown = "Contact/GetReportingToDropDown";
const getOfficeDropDown = "Contact/GetOfficeDropDown";
const getDepartmentDropDown = "Contact/GetDepartmentDropDown";
const getDesignationDropDown = "Contact/GetDesignationDropDown";
const getClientsPopOverDropDown = "Contact/GetClientsPopOverDropDown";
const getGenderDropDown = "Contact/GetGenderDropDown";
const getMaritalStatusDropDown = "Contact/GetMaritalStatusDropDown";
const getTestingData = "Contact/GetTestingData";


// Reports
const getControlsDropDown = "Reports/GetControlsDropDown";
const getExecutivesDropDown = "Reports/GetExecutivesDropDown";
const getServiceTimeContributionReport = "Reports/GetServiceTimeContributionReport";
const getExecutiveReportData = "ExecutiveExpense/GetExecutiveReportData";
const executiveExpenseReportViewerId = "ExecutiveExpense/ExecutiveExpenseReportViewerId";


// RequestAndApproval
const getRequestCategoryDropDown = "ApprovalWorkFlow/GetRequestCategoryDropDown";
const getRequestCategoryLandingPage = "ApprovalWorkFlow/GetRequestCategoryLandingPage";
const getRequestMaster = "ApprovalWorkFlow/GetRequestMaster";
const postRequestCategoryMaster = "ApprovalWorkFlow/SaveRequestCategoryMaster";
const getRequestCategoryDataGridData = "ApprovalWorkFlow/GetRequestCategoryDataGridData";
const getRequestCategoryFilterDropDown = "ApprovalWorkFlow/GetRequestCategoryFilterDropDown";
const postRequestCategoryMasterDelete = "ApprovalWorkFlow/RequestCategoryMasterDelete";
const getApprovalHierarchy = "ApprovalWorkFlow/GetApprovalHierarchy";
const getExecutivesList = "ApprovalWorkFlow/GetExecutivesList";
const getRequestCategoryApprovalHierarchy = "ApprovalWorkFlow/GetRequestCategoryApprovalHierarchy";
const getRequestMasterMaster = "ApprovalWorkFlow/GetRequestMasterMaster";
const getRequestCategoryMasterDropDown = "ApprovalWorkFlow/GetRequestCategoryMasterDropDown";
const postSaveRequestSlipMaster = "ApprovalWorkFlow/SaveRequestSlipMaster";
const getRequestMasterDropDownLandingPage = "ApprovalWorkFlow/GetRequestMasterDropDownLandingPage";
const getRequestStatus = "ApprovalWorkFlow/GetRequestStatus";
const getRequestApprovalData = "ApprovalWorkFlow/GetRequestApprovalData";
const postRequestSlipDetails = "ApprovalWorkFlow/SaveRequestSlipDetails";
const postRejectRequestSlipDetails = "ApprovalWorkFlow/RejectRequestSlipDetails";
const getRequestApprovalStatusData = "ApprovalWorkFlow/GetRequestApprovalStatusData";
const getApprovalStatusConfirmation = "ApprovalWorkFlow/GetApprovalStatusConfirmation";
const getRequestApprovalDropDownLandingPage = "ApprovalWorkFlow/GetRequestApprovalDropDownLandingPage";
const postRequestApprovalDeleteData = "ApprovalWorkFlow/RequestApprovalDeleteData";
const getRequestMasterFilterData = "ApprovalWorkFlow/GetRequestMasterFilterData";
const getRequestApprovalFilterData = "ApprovalWorkFlow/GetRequestApprovalFilterData";
const getRequestCategoryLevelStatus = "ApprovalWorkFlow/GetRequestCategoryLevelStatus";
const checkRequestNameData = "ApprovalWorkFlow/CheckRequestNameData";
const getRequestDateRange = "ApprovalWorkFlow/GetRequestDateRange";
const requestApprovalHierarchyDelete = "ApprovalWorkFlow/RequestApprovalHierarchyDelete";
const getExecutiveData = "ApprovalWorkFlow/GetExecutiveData";
const getLevelsData = "ApprovalWorkFlow/GetLevelsData";
const getApprovedData = "ApprovalWorkFlow/GetApprovedStatusData";
const checkApprovedStatus = "ApprovalWorkFlow/CheckApprovedStatus";
const getNextApproveStatus = "ApprovalWorkFlow/GetNextApproveStatus";
const getLevelsForRequestCategoryDelete = "ApprovalWorkFlow/GetLevelsForRequestCategoryDelete";



// OA MAster
const getOAMasterListingData = "OrderAcknowledgement/OAMasterFilter";
const getExecutivesDropData = "OrderAcknowledgement/GetExecutivesDropData";
const getOAProductsDropDown = "OrderAcknowledgement/GetOAProductsDropDown";
const getPOMasterListingData = "OrderAcknowledgement/GetPOMasterListingData";
const getCLientsDropDownPOModule = "OrderAcknowledgement/GetCLientsDropDownPOModule";
const getPODetailsDropDownList = "OrderAcknowledgement/GetPODetailsDropDownList";
const getPOGroupsDropDownData = "OrderAcknowledgement/GetPOGroupsDropDownData";
const getProductLinkingListingData = "OrderAcknowledgement/GetProductLinkingListingData";
const getProductNonCommercial = "OrderAcknowledgement/GetNonCommercialProducts";
const getIRQustionnariesData = "OrderAcknowledgement/GetIRQustionnariesData";
const getClientLandingPageGenerateReportUI = "OrderAcknowledgement/GetClientLandingPageGenerateReportUI";
const getGenerateReportFilter = "OrderAcknowledgement/GetGenerateReportFilter";
const getActivityDD = "OrderAcknowledgement/GetActivityDD";
const saveOAMasterListing = "OrderAcknowledgement/SaveOAMasterListing";
const getOAMasterEditData = "OrderAcknowledgement/OAMasterEditData";
const getOADetailsEditData = "OrderAcknowledgement/OADetailsEditData";
const getPaymentTermDropDown = "OrderAcknowledgement/GetPaymentTermDropDown";
const getClientDDOA = "OrderAcknowledgement/GetClientDDOA";
const savePOMasterListing = "OrderAcknowledgement/SavePOMasterListing";
const saveProductMasterLinking = "OrderAcknowledgement/SaveProductMasterLinking";
const productLinkingDeleteData = "OrderAcknowledgement/ProductLinkingDeleteData";
const getGenerateInstallationFilterData = "OrderAcknowledgement/GenerateInstallationFilterData";
const saveInstallationReportMaster = "OrderAcknowledgement/SaveInstallationReportMaster";
const getOAClient = "OrderAcknowledgement/GetOAClient";
const getClientMasterData = "OrderAcknowledgement/GetClientMasterData";
const getOAProductWiseDataForPO = "OrderAcknowledgement/GetOAProductWiseDataForPO";
const getOAProductWiseData = "OrderAcknowledgement/GetOAProductWiseData";
const getGenerateInstallationReportListing = "OrderAcknowledgement/GetGenerateInstallationReportListing";
const getPOMasterListing = "OrderAcknowledgement/GetPOMasterListing";
const getPOMasterEditData = "OrderAcknowledgement/GetPOMasterEditData";
const getGenerateInstallationReportEditData = "OrderAcknowledgement/GetGenerateInstallationReportEditData";
const generateInstallationReportExcelData = "OrderAcknowledgement/GenerateInstallationReportExcel";
const getCommercialProducts = "OrderAcknowledgement/GetCommercialProducts";
const getProductLinkingEditData = "OrderAcknowledgement/GetProductLinkingEditData";
const validateIssueIdAgainstOA = "OrderAcknowledgement/ValidateIssueIdAgainstOA";
const getGroupsData = "OrderAcknowledgement/GetGroupsData";
const generateOAPDFIsCostSheetTrue = "OrderAcknowledgement/GenerateOAPDF?IsCostSheet=true";
const generateOAPDFIsCostSheetFalse = "OrderAcknowledgement/GenerateOAPDF?IsCostSheet=false";
const getClientMasterEditData = "OrderAcknowledgement/GetClientMasterEditData";
const generateInstallationReportData = "OrderAcknowledgement/GenerateInstallationReportData";
const validateIssueId = "OrderAcknowledgement/ValidateIssueId";
const installationReportFilterData = "OrderAcknowledgement/InstallationReportFilterData";
const fillInstallationReportFilterData = "OrderAcknowledgement/FillInstallationReportFilterData";
const saveFillInstallationReport = "OrderAcknowledgement/SaveFillInstallationReport";



//  Dashboard powerBI
const getPowerBIDashBoard = "ExpenseManager/V1/PowerBIDashBoard";

// Issue
const getIssueDetails = "Issues/V1/GetIssueDetails";
const saveIssueIncident = "Issues/V1/SaveIssueIncident";
const getIssueDeliveryDateListing = "Issues/V1/GetIssueDeliveryDateListing"
const saveIssueDeliveryDate = "Issues/V1/SaveIssueDeliveryDate"

// Executive Expenses 
const expensesCategoryData = "ExecutiveExpense/ExpensesCategoryData";
const travelModeCategoryData = "ExecutiveExpense/TravelModeCategoryData";
const foodCategoryData = "ExecutiveExpense/FoodCategoryData";
const deleteExecutiveExpenses = "ExecutiveExpense/DeleteExecutiveExpenses";
const saveExecutiveExpenses = "ExecutiveExpense/SaveExecutiveExpenses";
const editExecutiveExpenses = "ExecutiveExpense/GetExecutiveExpensesEditData";
const filterExecutiveExpenses = "ExecutiveExpense/ExecutiveExpensesFilterData";
const getExpenseCategoryColumns = "ExecutiveExpense/GetExpenseCategoryColumns";


export const loginUrl =  ExecutiveLogin ;
export const dashBoardMenuUrl = dashBoardMenuUrlroute;
export const loginImageUrl = loginImageUrlroute;
export const ChangeLoginImageUrl = changeloginImageUrlroute;
export const ClientAliasUrl = getClientAliasUrlroute;
export const ProjectServerDrivesUrl = getProjectServerDrivesTab;
export const ProjectClientNamesUrl = getClientNames;
export const SaveProjectInformationUrl = postProjectInformation;
export const GetProjectInformationUrl = getProjectinformation;
export const GetClientsUrl = getClients;
export const GetServerProviderUrl = getServerProvider;
export const GetProjectinformationDropDownUrl= getProjectinformationDropDown;
export const GetDropdownUrl = getDropdown;
export const GetServiceProviderUrl = getServiceProvider;
export const GetClientAliasDropDownListUrl = getClientAliasDropDownList;
export const GetExecutiveListDropDownUrl = getExecutiveListDropDown;
export const GetExecutiveImmediateListDropDownUrl = getExecutiveImmediateListDropDown;
export const PostMarkAsAbsentUrl = postMarkAsAbsent;

// clientMaster
export const GetClientMasterUrl = getClientMaster;
export const SaveClientDetailsUrl = saveClientDetails;
export const GetProjectStatusDropDownUrl = getProjectStatusDropDown;
export const GetClientMasterDropDownDataUrl = getClientMasterDropDownData;
export const GetClientMasterFilterDropDownUrl = getClientMasterFilterDropDown;
export const GetTeamAndPrefferedClientsDropdownUrl = getTeamAndPrefferedClientsDropdown;
export const GetClientGroupingDropDownUrl = getClientGroupingDropDown;
export const GetClientNamesDDUrl = getClientNamesDD;
export const GetClientNameValidationUrl = getClientNameValidation;
export const GetERPCodeValidationUrl = getERPCodeValidation;
export const GetProjectNamesDropDownUrl = getProjectNamesDropDown;
export const GetDesignationNameUrl = getDesignationName;
export const GetDepartmentNameUrl = getDepartmentName;
export const GetRolesUrl = getRoles;
export const GetClientMasterListingDataUrl = getClientMasterListingData;
export const GetExecutiveInfoUrl = getExecutiveInfo;



// ClientInstallationLocations
export const GetClientInstallationLocationDataUrl = getClientInstallationLocationData;
export const GetContactTypeIdDropDownUrl = getContactTypeIdDropDown;
export const GetAMCDropDownDataUrl = getAMCDropDownData;
export const GetClassificationDropDownUrl = getClassificationDropDown;
export const GetSiteAutocompleteUrl = getSiteAutocomplete;
export const GetProjectVersionAutocompleteUrl = getProjectVersionAutocomplete;
export const GetClientsDropDownUrl = getClientsDropDown;
export const EditClientInstallationLocationsUrl = editClientInstallationLocations;
export const EditContactMasterUrl = editContactMaster;
export const SaveClientInstallationLocationsUrl = postClientInstallationLocations; 
export const SaveContactMasterUrl = postContactMaster; 
export const GetContactMasterDataUrl = getContactMasterData;
export const GetClientInstallationContactMasterUrl = getClientInstallationContactMaster;
export const BulkSaveUrl = postbulkSave;
export const SaveContactSiteUrl = postContactSite;
export const GetClientInstallationFilterDropDownUrl = getClientInstallationFilterDropDown;
export const GetProductMasterEditDataUrl = getProductMasterEditData;

// Contact Master
export const GetContactMasterUrl = getContactMaster;



// ProductMAster
export const GetProductMasterListingDataUrl = getProductMasterListingData;
export const GetProductMasterAutocompleteUrl = getProductMasterAutocomplete;
export const SaveProductMasterDetailsUrl = saveProductMaster;
export const GetProductMasterFilterDropDownUrl = getProductMasterFilterDropDown;
export const GetProductNameValidationUrl = getProductNameValidation;

// Api Master
export const GetApiMasterIdUrl = getApiMasterId;
export const GetAPIMasterListingDataUrl = getAPIMasterListingData;
export const SaveAPIMasterUrl = saveAPIMaster;
export const GetAPIMasterEditDataUrl = getAPIMasterEditData;
export const CheckAPIIdExistUrl = checkAPIIdExist;

// Service
export const GetServiceWeightageUrl = getServiceWeightage;
export const GetTeamNameUrl = getTeamName;
export const GetExecutiveNameUrl = getExecutiveName;
export const SaveServiceWeightage = postServiceWeightage;
export const SaveServiceWeightageAll = postServiceWeightageAll;
export const FileterServiceWeightage = postfilterServiceWeightage;
export const GetExecutiveNameDepartmentDDUrl = getExecutiveNameDepartmentDD;


// ServiceEntry

export const GetServiceEntryClientNameUrl = getServiceEntryClientName;
export const GetServiceEntrySiteNameUrl = getServiceEntrySiteName;
export const GetServiceEntryActivityUrl = getServiceEntryActivity;
export const GetServiceEntryProjectUrl = getServiceEntryProject;
export const SaveQuickServiceEntryUrl = postQuickServiceEntry;
export const GetFilteredServicEntrySectionCUrl = getFilteredServicEntrySectionC;
export const GetActivityDataGridUrl = getActivityDataGrid;
export const GetFilteredServicEntrySectionDUrl = getFilteredServicEntrySectionD;
export const GetServiceEntryTotalServiceCountUrl = getServiceEntryTotalServiceCount;
export const GetServiceEntryTotalMinutesUrl = getServiceEntryTotalMinutes;
export const UpdateQuickServiceEntryUrl = updateQuickServiceEntry;
export const GetDefaultIssueIdUrl = getDefaultIssueId;


// Login
export const GetUserPhotoUrl = getUserPhoto;
export const addAttendanceUrl = postaddAttendance;
export const GetAttendanceUrl = getAttendance;
export const GetUserAccessRightsUrl = getUserAccessRights;
export const GetDashboardMenusUrl = getDashboardMenus;
export const SaveCopyAccessRightsUrl = saveCopyAccessRights;


// Contact Popover
export const GetReportingToDropDownUrl = getReportingToDropDown;
export const GetOfficeDropDownUrl = getOfficeDropDown;
export const GetDepartmentDropDownUrl = getDepartmentDropDown;
export const GetDesignationDropDownUrl = getDesignationDropDown;
export const GetClientsPopOverDropDownUrl = getClientsPopOverDropDown;
export const GetGenderDropDownUrl = getGenderDropDown;
export const GetMaritalStatusDropDownUrl = getMaritalStatusDropDown;
export const GetTestingDataUrl = getTestingData;

// Reports
export const GetControlsDropDownUrl = getControlsDropDown;
export const GetExecutivesDropDownUrl = getExecutivesDropDown;
export const GetServiceTimeContributionReportUrl = getServiceTimeContributionReport;
export const GetExecutiveReportDataUrl = getExecutiveReportData;
export const ExecutiveExpenseReportViewerIdUrl = executiveExpenseReportViewerId;

// RequestAndApprocal
export const GetRequestCategoryDropDownUrl = getRequestCategoryDropDown;
export const GetRequestCategoryLandingPageUrl = getRequestCategoryLandingPage;
export const GetRequestMasterUrl = getRequestMaster;
export const SaveRequestCategoryMasterUrl = postRequestCategoryMaster;
export const GetRequestCategoryDataGridDataUrl = getRequestCategoryDataGridData;
export const GetRequestCategoryFilterDropDownUrl = getRequestCategoryFilterDropDown;
export const RequestCategoryMasterDeleteUrl = postRequestCategoryMasterDelete;
export const GetApprovalHierarchyUrl = getApprovalHierarchy;
export const GetExecutivesListUrl = getExecutivesList;
export const GetRequestCategoryApprovalHierarchyUrl = getRequestCategoryApprovalHierarchy;
export const GetRequestMasterMasterUrl = getRequestMasterMaster;
export const GetRequestCategoryMasterDropDownUrl = getRequestCategoryMasterDropDown;
export const SaveRequestSlipMasterUrl = postSaveRequestSlipMaster;
export const GetRequestMasterDropDownLandingPageUrl = getRequestMasterDropDownLandingPage;
export const GetRequestStatusUrl = getRequestStatus;
export const GetRequestApprovalDataUrl = getRequestApprovalData;
export const SaveRequestSlipDetailsUrl = postRequestSlipDetails;
export const RejectRequestSlipDetailsUrl = postRejectRequestSlipDetails;
export const GetRequestApprovalStatusDataUrl = getRequestApprovalStatusData;
export const GetApprovalStatusConfirmationUrl = getApprovalStatusConfirmation;
export const GetRequestApprovalDropDownLandingPageUrl = getRequestApprovalDropDownLandingPage;
export const RequestApprovalDeleteDataUrl = postRequestApprovalDeleteData;
export const GetRequestMasterFilterDataUrl = getRequestMasterFilterData;
export const GetRequestApprovalFilterDataUrl = getRequestApprovalFilterData;
export const GetRequestCategoryLevelStatusUrl = getRequestCategoryLevelStatus;
export const CheckRequestNameDataUrl = checkRequestNameData;
export const GetRequestDateRangeUrl = getRequestDateRange;
export const RequestApprovalHierarchyDeleteUrl = requestApprovalHierarchyDelete;
export const GetExecutiveDataUrl = getExecutiveData;
export const GetLevelsDataUrl = getLevelsData;
export const GetApprovedStatusDataUrl = getApprovedData;
export const CheckApprovedStatusUrl = checkApprovedStatus;
export const GetNextApproveStatusUrl = getNextApproveStatus;
export const GetLevelsForRequestCategoryDeleteUrl = getLevelsForRequestCategoryDelete;
export const GCheckCateogoryIsDeleteUrl = getLevelsForRequestCategoryDelete;




// OAMASster

export const GetOAMasterListingDataUrl = getOAMasterListingData;
export const GetExecutivesDropDataUrl = getExecutivesDropData;
export const GetOAProductsDropDownUrl = getOAProductsDropDown;
export const GetPOMasterListingDataUrl = getPOMasterListingData;
export const GetCLientsDropDownPOModuleUrl = getCLientsDropDownPOModule;
export const GetPODetailsDropDownListUrl = getPODetailsDropDownList;
export const GetPOGroupsDropDownDataUrl = getPOGroupsDropDownData;
export const GetProductLinkingListingDataUrl = getProductLinkingListingData;
export const GetProductNonCommercialUrl = getProductNonCommercial;
export const GetIRQustionnariesDataUrl = getIRQustionnariesData;
export const GetClientLandingPageGenerateReportUIUrl = getClientLandingPageGenerateReportUI;
export const GetGenerateReportFilterUrl = getGenerateReportFilter;
export const GetActivityDDUrl = getActivityDD;
export const SaveOAMasterListingUrl = saveOAMasterListing;
export const GetOAMasterEditDataUrl = getOAMasterEditData;
export const GetOADetailsEditDataUrl = getOADetailsEditData;
export const GetPaymentTermDropDownUrl = getPaymentTermDropDown;
export const GetClientDDOAUrl = getClientDDOA;
export const SavePOMasterListingUrl = savePOMasterListing;
export const SaveProductMasterLinkingUrl = saveProductMasterLinking;
export const ProductLinkingDeleteDataUrl = productLinkingDeleteData;
export const GetGenerateInstallationFilterDataUrl = getGenerateInstallationFilterData;
export const SaveInstallationReportMasterUrl = saveInstallationReportMaster;
export const GetOAClientUrl = getOAClient;
export const GetClientMasterDataUrl = getClientMasterData;
export const GetOAProductWiseDataForPOUrl = getOAProductWiseDataForPO;
export const GetOAProductWiseDataUrl = getOAProductWiseData;
export const GetGenerateInstallationReportListingUrl = getGenerateInstallationReportListing;
export const GetPOMasterListingUrl = getPOMasterListing;
export const GetPOMasterEditDataUrl = getPOMasterEditData;
export const GetGenerateInstallationReportEditDataUrl = getGenerateInstallationReportEditData;
export const GenerateInstallationReportExcelDataUrl = generateInstallationReportExcelData;
export const GetCommercialProductsUrl = getCommercialProducts;
export const GetProductLinkingEditDataUrl = getProductLinkingEditData;
export const ValidateIssueIdAgainstOAUrl = validateIssueIdAgainstOA;
export const GetGroupsDataUrl = getGroupsData;
export const GenerateOAPDIsCostSheetTrueUrl = generateOAPDFIsCostSheetTrue;
export const GenerateOAPDIsCostSheetFalseUrl = generateOAPDFIsCostSheetFalse;
export const GetClientMasterEditDataUrl = getClientMasterEditData;
export const GenerateInstallationReportDataUrl = generateInstallationReportData;
export const InstallationReportFilterDataUrl = installationReportFilterData;
export const FillInstallationReportFilterDataUrl = fillInstallationReportFilterData;
export const SaveFillInstallationReportUrl = saveFillInstallationReport;




//  Dashboard POwerBI
export const PowerBIDashBoardUrl = getPowerBIDashBoard;

// Issue
export const GetIssueDetailsUrl = getIssueDetails;
export const SaveIssueIncidentUrl = saveIssueIncident;
export const GetIssueDeliveryDateListingUrl = getIssueDeliveryDateListing;
export const SaveIssueDeliveryDateUrl = saveIssueDeliveryDate;
export const ValidateIssueIdUrl = validateIssueId

// Executive Expenses 
export const ExpensesCategoryDataUrl = expensesCategoryData;
export const TravelModeCategoryDataUrl = travelModeCategoryData;
export const FoodCategoryDataUrl = foodCategoryData;
export const DeleteExecutiveExpensesUrl = deleteExecutiveExpenses;
export const SaveExecutiveExpensesUrl = saveExecutiveExpenses;
export const GetExecutiveExpensesEditDataUrl = editExecutiveExpenses;
export const FilterExecutiveExpensesUrl = filterExecutiveExpenses;
export const GetExpenseCategoryColumnsUrl = getExpenseCategoryColumns

 //     export const loginUrl = 'http://localhost:7729/ExpenseManager/V1/ExecutiveLogin';
 //   export const dashBoardMenuUrl = 'http://localhost:7729/Service/V1/DashBoardMenu';




