import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, FormLabel, InputLabel, Select, MenuItem, Button, Grid, RadioGroup, Radio } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fbe9e7', // Set the background color to #fbe9e7
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function FillInstallationReportDetails({ fillInstallation }) {
    console.log("fillInstallation detail ", fillInstallation, fillInstallation.fillInstallation?.FillInstallationReportFilterData,
    )

    const installationStatus = [
        { id: 1, Name: 'Completed' },
        { id: 2, Name: 'Cancelled' },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const productId = proMaster.ID;



    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    const [insStatusId, setInsStatusId] = useState('');
    const handleInsStatus = (id) => {
        setInsStatusId(id);

    };
    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        navigate('/dashboard/oa/fillintallationreport', { replace: true });
        setOpenModalCancel(false);
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };

    // dropdown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const handleClient = (id) => {
        setSelectedClientId(id);

    };

    const [effectiveFromValue, setEffectiveFromValue] = useState(new Date());

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };


    const [activityData, setActivityData] = useState([]);
    const [activityDataValue, setActivityDataValue] = useState('');
    const [installationRemark, setInstallationRemark] = useState('');

    const handleActivity = (id) => {
        setActivityDataValue(id);

    };

    useEffect(() => {
        URLS.getClientLandingPageGenerateReportUI()
            .then((response) => {

                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getActivityDD()
            .then((response) => {

                setActivityData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [selectedTime, setSelectedTime] = useState(new Date());

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };
    const handleInstallationRemark = (e) => {
        setInstallationRemark(e.target.value);
    };
    const fields = fillInstallation.fillInstallation?.FillInstallationReportFilterData || [];

    const [fieldValues, setFieldValues] = useState({});
    const parentPanels = fields.filter(field => field.ParentId === 0);
    console.log("parentPanels", parentPanels)

    const childFields = fields.filter(field => field.ParentId !== 0);
    console.log("childFields", childFields)

    // Handle changes in form elements
    const handleField = (id, value) => {
        setFieldValues((prevValues) => ({
            ...prevValues,
            [id]: value // Update only the specific field based on its id
        }));
    };


    // Handle Date changes
    // const handleDateChange = (name, date) => {
    //    setFormData((prevData) => ({ ...prevData, [name]: Moment(date).format('YYYY-MM-DD') }));
    // };
    const renderField = (fields) => {
        const preSelectedValue = fields.Answers || ''; // Get the pre-selected value from the Answers property

        switch (fields.ControlType) {
            case 'TextBox': {
                return (
                    <TextField
                        label={fields.QuestionDescription}
                        fullWidth
                        size="small"
                        onChange={(e) => handleField(fields.Sequence, e.target.value)}
                        value={fieldValues[fields.Sequence] || preSelectedValue}
                    />
                );
            }
            case 'DropDownList': {
                // Parse the DataValues string to extract the options
                const options = fields.DataValues
                    ? fields.DataValues.replace(/[\\[\]]/g, '') // Remove brackets
                        .split('|') // Split by pipe delimiter
                    : [];
                return (
                    <FormControl fullWidth size="small">
                        <InputLabel>{fields.QuestionDescription}</InputLabel>
                        <Select
                            label={fields.QuestionDescription}
                            onChange={(e) => handleField(fields.Sequence, e.target.value)}
                            value={fieldValues[fields.Sequence] || preSelectedValue}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
            case 'Date Format': {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={fields.QuestionDescription}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            onChange={(date) => handleField(fields.Sequence, date)}
                            value={fieldValues[fields.Sequence] || null}
                        />
                    </LocalizationProvider>
                );
            }
            case 'RadioButton': {
                // Initialize radio options and default selected value
                let radioOptions = [];
                let preSelected = 'No'; // Default selected value

                if (fields.DataValues) {
                    // Clean up DataValues to extract relevant options
                    const cleanedDataValues = fields.DataValues
                        .replace(/[{}]/g, '') // Remove curly braces
                        .split(',') // Split by comma to separate values
                        .map(option => option.trim()) // Trim whitespace
                        .filter(option => option.length > 0); // Remove empty entries

                    // Loop through cleaned values to extract radio options and determine default selection
                    cleanedDataValues.forEach(option => {
                        if (option.includes('No')) {
                            radioOptions.push('No'); // Add No option
                            preSelected = 'No'; // Set pre-selected value to No
                        }
                        if (option.includes('Yes')) {
                            radioOptions.push('Yes'); // Add Yes option
                        }

                        // Check for special case where Yes|No means both options should be included
                        if (option === '[Yes|No]') {
                            radioOptions = ['Yes', 'No']; // Set both options
                            preSelected = 'No'; // Default selected to No
                        }
                    });
                }

                // Log an error if no valid options are found
                if (radioOptions.length === 0) {
                    console.error('No valid radio options found for field:', fields.QuestionDescription);
                }

                return (
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{fields.QuestionDescription}</FormLabel>
                        <RadioGroup
                            row
                            value={fieldValues[fields.Sequence] || preSelected} // Use preSelectedValue or from fieldValues
                            onChange={(e) => handleField(fields.Sequence, e.target.value)} // Handle changes
                            sx={{ flexDirection: 'row' }}
                        >
                            {radioOptions.map((option, index) => (
                                <FormControlLabel
                                    key={`${fields.QuestionId}-${index}`} // Unique key
                                    value={option} // Clean the value to store
                                    control={<Radio />}
                                    label={option} // Label for display
                                    sx={{ marginRight: 2 }} // Add spacing
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            }
            default: {
                return null;
            }
        }
    };


    const formik = useFormik({
        initialValues: {

            ClientId: selectedClientId,
            ProductId: fillInstallation.ProductId,
            SiteId: fillInstallation.Issueid,
            InstallationStatus: insStatusId,
            InstallationStatusDate: effectiveFromValue,
            InstallationRemark: fillInstallation.InstallationRemark,
        },

        onSubmit: (values, actions) => {
            const QuestionAnswersData = fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(field => ({
                QuestionId: Number(field.QuestionId), // Using QuestionId from the field
                Answer: fieldValues[field.Sequence] || field.Answers || '' // Use field value or default to Answers if available
            }));

            const installationReportQuestionnairesMasterIds = new Set(
                fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(item => item.InstallationReportQuestionnairesMasterId)
            );
            const installationReportQuestionnairesMasterId = installationReportQuestionnairesMasterIds.size > 0
                ? Array.from(installationReportQuestionnairesMasterIds)[0] // Take the first unique ID
                : 0;
            const formattedDate = format(effectiveFromValue, 'dd-MMM-yyyy');
            // Build the full payload
            const payload = {
                Id: 0, // Assuming default value for Id, update if necessary
                OAInstallationReportMasterId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].OAInstallationReportMasterId, // Update if you have a dynamic ID for InstallationReportMasterId
                InstallationReportQuestionnairesMasterId: installationReportQuestionnairesMasterId,
                InstallationStatus: insStatusId,
                InstallationStatusUpdateDateTime: formattedDate,
                Remark: installationRemark || "",// Update if you have a dynamic ID for InstallationReportQuestionnairesMasterId
                QuestionAnswers: QuestionAnswersData // Transformed question-answers array
            };
            URLS.saveFillInstallationReport(payload)
                .then((response) => {
                    if (response.data.status === "false") {
                                enqueueSnackbar("Record saved successfully", { variant: 'success' });
                                    navigate('/dashboard/master/productmaster', { replace: true });
                     
                    }
                })
                .catch(error => {
                    handleError(error, enqueueSnackbar);
                });
            console.log("payload", payload);
        },
    });
    console.log("********************************", fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].OAInstallationReportMasterId)
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={null} >
                        <Grid container spacing={2} alignItems="center">
                            {fillInstallation.fillInstallation?.ClientProductDistributorDetails?.length > 0 && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography>Client Name: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.ClientName}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Distributor Name: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.DistributorName || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Distributor Contact: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.DistributorContact || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Product Name: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.ProductName}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>P.O. No. and Date: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.PONumber || ''}</Typography>
                                    </Grid>
                                </>
                            )}

                        </Grid>
                    </AccordionSummary>
                    {parentPanels.map((parent) => (
                        <Accordion key={parent.Sequence}>
                            <AccordionSummary>
                                <Typography variant="h6">{parent.QuestionDescription}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    {childFields
                                        .filter(field => field.ParentId === parent.Sequence) // Correct filter
                                        .map((field) => {
                                            console.log("Rendering field:", field);
                                            return (
                                                <Grid item xs={4} key={field.Sequence}>
                                                    {renderField(field)}
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth size="small">
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                    Installation Status:
                                </InputLabel>
                                <Select
                                    label="Installation Status"
                                    size="small"
                                    value={insStatusId}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}

                                    onChange={(event) => handleInsStatus(event.target.value)}
                                >

                                    {installationStatus.map((category) => (
                                        <MenuItem
                                            key={category.id}
                                            value={category.id}
                                        >
                                            {category.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                           
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker

                                    label="Installation  Date"
                                    inputFormat="dd/MMM/yyyy"
                                    value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                    onChange={handleEffFrom}
                                    maxDate={new Date()}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Installation Remark" fullWidth size="small"
                                onChange={handleInstallationRemark}
                                value={installationRemark}
                                multiline       />
                           
                        </Grid>
                    </Grid>

                    <Accordion>
                        <AccordionSummary>
                            <Typography variant="h6">Service Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                            Activity
                                        </InputLabel>
                                        <Select
                                            label="Activity"
                                            size="small"
                                            value={activityDataValue }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7',
                                                        maxHeight: '200px',
                                                    },
                                                },
                                            }}

                                            onChange={handleActivity}
                                        >

                                            {activityData.map((category) => (
                                                <MenuItem
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Start Time" fullWidth size="small"
                                        onChange={handleInstallationRemark}
                                        value={installationRemark}
                                        multiline />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Service In Min" fullWidth size="small"
                                        onChange={handleInstallationRemark}
                                        value={installationRemark}
                                        multiline />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Remark" fullWidth size="small"
                                        onChange={handleInstallationRemark}
                                        value={installationRemark}
                                        multiline
                                        style={{width: '30%'} }
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Back
                        </Button>
                    </Stack>
                </Stack>


            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider >
    );
}