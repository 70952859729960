/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Box, Button, Card, FormControl, FormControlLabel, Tab, Typography,Tabs,FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useFormik, Form, FormikProvider } from 'formik';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Moment from 'moment';
import { startOfMonth, endOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';
import ModalPopUp from '../../Common/ModalPopUp';
import { handleError } from '../../Common/Utils';
import ExecutiveExpensesDetailsTabPanel from './ExecutiveExpensesDetailsTabPanel';
import Scrollbar from '../../Scrollbar';


// -----------------------------------------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExecutiveExpensesDetails( {executiveExpenses} ) {
    console.log("executiveExpenses>>>>>", executiveExpenses)
    const navigate = useNavigate();

    // const productId = proMaster.ID;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [openModalForCancel, setOpenModalCancel] = useState(false);

    const getCurrentFormattedDate = () => Moment().format('YYYY/MM/DD');

    // Function to format a given date to 'YYYY/MM/DD'
    const getFormattedDate = (date) => Moment(date).format('YYYY/MM/DD');

    // Determine the initial date based on executiveExpenses
    const initialDate = executiveExpenses[0]?.Date
        ? getFormattedDate(executiveExpenses[0]?.Date)
        : getCurrentFormattedDate();

    const [fromDateValue, setFromDateValue] = useState(initialDate);
    const [selectedType, setSelectedType] = useState(executiveExpenses[0]?.Type || 1);
    const [selectedAllocationType, setSelectedAllocationType] = useState(executiveExpenses[0]?.AllocationType || 2);
   
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);

    // Expenses Category dropdown
    const [expensesCategory, setExpensesCategory] = useState(executiveExpenses[0]?.ExpenseCategory || ''); 
    const [selectedexpensesCategory, setSelectedExpensesCategory] = useState([]);

    // Travel Mode Category dropdown
    const [travelCategory, setTravelCategory] = useState(executiveExpenses[0]?.TravelMode || '');
    const [selectedeTravelCategory, setSelectedTravelCategory] = useState([]);

    // Food Category dropdown
    const [foodCategory, setFoodCategory] = useState(executiveExpenses[0]?.FoodCategory || '');
    const [selectedeFoodCategory, setSelectedFoodCategory] = useState([]);

    // client dropdown
    const [selectedClientId, setSelectedClientId] = useState(executiveExpenses[0]?.ClientId || '');
    const [selectedClient, setSelectedClient] = useState([]);

    // Project dropdown
    const [selectProject, setSelectProject] = useState(executiveExpenses[0]?.ProjectId || '');
    const [selectedProjectId, setSelectedProjectId] = useState([]);

    // allocation grid data
    const [gridRows, setGridRows] = useState([]);
    const [allocationValue, setAllocationValue] = useState(executiveExpenses[0]?.AllocationValue || '');

    const { enqueueSnackbar } = useSnackbar();
    const [userId, setUserId] = useState([]);

    const [amount, setAmount] = useState(executiveExpenses[0]?.Amount || '');
    const [expenseAmount, setExpenseAmount] = useState(executiveExpenses[0]?.Amount || '');

    const [remark, setRemark] = useState(executiveExpenses[0]?.Remark || '');

    const [expenseCategoryId, setExpenseCategoryId] = useState('');
    const [selectedExpenseCategoryId, setSelectedExpenseCategoryId] = useState('');

    const [editingRowId, setEditingRowId] = useState(null);
    const [newAllocationValue, setNewAllocationValue] = useState('');


    const [fieldSets, setFieldSets] = useState([
        {
            ExpenseMasterId: 0,
            AllocationType: "",
            ProjectId: "",
            ClientId: "",
            AllocationValue: "",
          
        },
    ]);

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/executiveexpenses', { replace: 1 });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };
    const clearExpenseData = () => {
        setFieldSets([{ ExpenseMasterId: 0, AllocationType: "", ProjectId: "",  AllocationValue: ""}]);
        setFromDateValue(startOfMonth(new Date()));
        setExpensesCategory('');
        setTravelCategory('');
        setFoodCategory('');
        setSelectProject('');
        setAmount('');
        setExpenseAmount('')
        setRemark('');
        formik.setFieldValue('Amount', ''); // Synchronize with Formik
        formik.setFieldValue('Remark', ''); 
        setSelectedExpenseCategoryId('');
        setGridRows([]);
        setSelectedClientId('');
        setAllocationValue('')
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };
    const handleEffFrom = (newValue) => {
        const formattedDate = Moment(newValue).format('YYYY/MM/DD');
        setFromDateValue(formattedDate);
    };
    const handleRadioChange = (event) => {
      
        const newType = parseInt(event.target.value, 10); 
        if (newType === 2 || newType === 3 || newType === 1) {
            clearExpenseData();
            setFromDateValue(getFormattedDate(new Date())); 
        } 
        setSelectedType(newType);
    };
   

    const handleAllocationRadioChange = (event) => {
        const newType = parseInt(event.target.value, 10); 

        setSelectedAllocationType(newType);
    };

    const handleTabChangeValue = (event, newValue) => {
        setValue(newValue);
    };
   
    useEffect(() => {
        if (executiveExpenses[0]?.ClientId) {
            setSelectedClientId(executiveExpenses[0]?.ClientId);
        }
    }, [executiveExpenses]);


    const handleShow = () => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            return updatedFieldSets;
        });
        setHide(false)
    };

    const handleNextClick = () => {
        setValue(1); 
    }
    const handlePreviousClick = () => {
        setValue(0);
    }

    const handleProjectChange = (id) => {
        
        setSelectProject(id);
    };
    const handleClientChange = (id) => {
       
        setSelectedClientId(id);
    };

    const handleAllocationValueChange = (event) => {
        const { value } = event.target;
            setAllocationValue(value);
    };

    const handleExpensesCategory = (id) => {
        setExpensesCategory(id); // Update the currently selected category

        URLS.getExpenseCategoryColumns({ ExpenseCategoryId: id })
            .then((response) => {
                const visibleColumns = response.data[0]?.VisibleColumns || '';
                setSelectedExpenseCategoryId(visibleColumns);
            })
            .catch(error => {
                console.log("Error fetching expense category columns:", error);
                setSelectedExpenseCategoryId('');
            });
    };

    const handleTravelCategory = (id) => {
        setTravelCategory(id)
    }
    const handleFoodCategory = (id) => {
        setFoodCategory(id)
    }

    const handleAddRow = () => {
        if (!selectedClientId) {
            enqueueSnackbar("Please select a client");
            return;
        }
        if (!selectProject) {
            enqueueSnackbar("Please select a project");
            return;
        }
        if (!allocationValue) {
            enqueueSnackbar("Please select a allocation value");
            return;
        }
        const client = selectedClient.find(client => client.ClientId === selectedClientId);
        const project = selectedProjectId.find(project => project.ProjectId === selectProject);

        const newRow = {
            id: gridRows.length + 1,

            clientId: client.ClientId, // Store ClientId
            clientName: client.Name, // Store Client Name for display
            projectId: project.ProjectId, // Store ProjectId
            projectName: project.Name, // Store Project Name for display
            allocation: allocationValue
        };

        setGridRows([...gridRows, newRow]);

        setSelectedClientId('');
        setSelectProject('');
        setAllocationValue('');
    };
        console.log("check>>>>", selectedClientId)


    useEffect(() => {
        if (Array.isArray(executiveExpenses) && executiveExpenses[0]?.ExpenseMasterId !== 0) {
            const formattedGridRows = executiveExpenses.map((item, index) => {
                // Find the client and project names
                const client = selectedClient.find(client => client.ClientId === item.ClientId);
                const project = selectedProjectId.find(project => project.ProjectId === item.ProjectId);

                return {
                    id: index, // Set id as index
                    clientId: item.ClientId,
                    clientName: client ? client.Name : '',
                    projectId: item.ProjectId,
                    projectName: project ? project.Name : '',
                    allocation: item.AllocationValue
                };
            });
            setGridRows(formattedGridRows);
        }
    }, [executiveExpenses, selectedClient, selectedProjectId]);

    const handleFieldChange = (index, field, value) => {
        const newFieldSets = [...fieldSets];
        newFieldSets[index] = {
            ...newFieldSets[index],
            [field]: value
        };
        setFieldSets(newFieldSets);
    };

    const handleDelete = (event, cellValues) => {
        // Filter out the row with the id of the clicked row
        setGridRows((prevRows) =>
            prevRows.map((row) =>
                row.id === cellValues.row.id ? { ...row, isDelete: 1 } : row
            )
        );
        // Update the state with the new rows
    };

    const handleEdit = (rowId) => {
        const row = gridRows.find(row => row.id === rowId);
        if (row) {
            setEditingRowId(rowId);
            setNewAllocationValue(row.allocation);
        } else {
            console.error("Row not found for ID:", rowId);
        }
    };

    const handleSave = (id) => {
        const updatedRows = gridRows.map((row) =>
            row.id === id ? { ...row, allocation: newAllocationValue } : row
        );
        setGridRows(updatedRows);
        setEditingRowId(null);
        setNewAllocationValue('');
    };
    const isDisabled = (value) => {
        switch (executiveExpenses[0]?.Type) {
            case 1:
                return value === 2 || value === 3;
            case 2:
                return value === 1 || value === 3;
            case 3:
                return value === 1 || value === 2;
            default:
                return false;
        }
    };

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    useEffect(() => {
        URLS.getServiceEntryClientName()
            .then((response) => {
                setSelectedClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getProjectNamesDropDown()
            .then((response) => {
                setSelectedProjectId(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });

        URLS.getExpensesCategoryData()
            .then((response) => {
                setSelectedExpensesCategory(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });

        URLS.getTravelModeCategoryData()
            .then((response) => {
                setSelectedTravelCategory(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });

        URLS.getFoodCategoryData()
            .then((response) => {
                setSelectedFoodCategory(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
        URLS.getExpenseCategoryColumns({ ExpenseCategoryId: expensesCategory })
            .then((response) => {
                const visibleColumns = response.data[0]?.VisibleColumns || '';
                setSelectedExpenseCategoryId(visibleColumns);
            })
            .catch(error => {
                console.log("Error fetching expense category columns:", error);
                setSelectedExpenseCategoryId('');
            });

    }, []);

    const expensesValidationSchema = Yup.object({
       /* Amount: Yup.string().required('Please enter an Amount').positive('Amount must be a positive number'), */
        Remark: Yup.string().required('Please enter a Remark'),
    });


    console.log("selectedExpenseCategoryId", selectedExpenseCategoryId)
    const formik = useFormik({
        initialValues: {
            "ExpenseDate": fromDateValue || "",
            "Type": selectedType || "",
            "ExpenseCategory": expensesCategory,
            "TravelMode": travelCategory,
            "FromLocation": executiveExpenses[0]?.FromLocation || "",
            "ToLocation": executiveExpenses[0]?.ToLocation || "",
            "Amount": amount || "",
            "Remark": remark || "",
            "Count": executiveExpenses[0]?.Count || "",
            "City": executiveExpenses[0]?.City || "",
            "HotelName": executiveExpenses[0]?.HotelName || "",
            "AllocationType": selectedAllocationType
          
        },
        onSubmit: (values, actions) => {

            let errorMessage = ""; // Initialize an error message variable

            // Step 1: Validate Expense Category
            if (selectedType === 1 && !expensesCategory) {
                errorMessage = "Please select Expense Category";
            }

            // Step 2: Validate Amount and Remark for selectedType 2
            else if (selectedType === 2 || selectedType === 3) {
                if (!amount) {
                    errorMessage = "Please select Amount";
                } else if (!values.Remark) {
                    errorMessage = "Please select Remark";
                }
            }

            // Step 3: Validate Count if selectedExpenseCategoryId is set
            if (selectedExpenseCategoryId && values.Count) {
                const countValue = parseFloat(values.Count);
                if (countValue <= 0) {
                    errorMessage = "Count must be greater than 0";
                }
            }

            // Step 4: Validate Allocation Values
            if (!errorMessage) {
                const amountNumeric = parseFloat(values.Amount) || parseFloat(amount);
                console.log("Parsed Amount:", amountNumeric);
                if (selectedType === 1 && selectedAllocationType === 2) {
                    const totalPercentageAllocation = gridRows
                        .filter(row => row.isDelete !== 1)
                        .reduce((sum, row) => sum + parseFloat(row.allocation || 0), 0);

                    const calculatedTotal = (totalPercentageAllocation / 100) * amountNumeric;
                    console.log("Total Percentage Allocation:", totalPercentageAllocation);
                    console.log("Calculated Total based on Percentage:", calculatedTotal);

                    if (calculatedTotal !== amountNumeric) {
                        errorMessage = "The total Percentage allocation value does not match the entered amount.";
                    }
                } else if (selectedType === 1 && selectedAllocationType === 1) {
                    const totalNetAllocation = gridRows
                        .filter(row => row.isDelete !== 1)
                        .reduce((sum, row) => sum + parseFloat(row.allocation || 0), 0);

                    if (totalNetAllocation !== amountNumeric) {
                        errorMessage = "The total Net allocation value does not match the entered amount.";
                    }
                }
            }


            // If there's an error message, show it and return early
            if (errorMessage) {
                enqueueSnackbar(errorMessage);
                return;
            }


            
            const payload = {
                "ExpenseMasterId": executiveExpenses[0]?.Id || 0,
                "ExpenseDate": fromDateValue,
                "Type": selectedType || "",
                "ExpenseCategory": expensesCategory || "",
                "Remark": values.Remark || "",
                "Amount": values.Amount || "",
                "TravelMode": travelCategory,
                "FromLocation": values.FromLocation || "",
                "ToLocation": values.ToLocation || "",
                "Count": values.Count || "",
                "City": values.City || "",
                "HotelName": values.HotelName || "",
                "FoodCategory": foodCategory,
                "CreatedBy": userId,
                "EditedBy": userId,
                "DeletedBy":userId,
                "IsDeleted": false,
                "ExpenseCategoryColumnsModel": [],
                "AllocationItems": gridRows.map((rows, index) => ({
                    AllocationId: executiveExpenses[index]?.Id1 || 0,
                    ExpenseMasterId: executiveExpenses[0]?.ExpenseMasterId || 0,
                    AllocationValue: rows.allocation,
                    AllocationType: selectedAllocationType,
                    ClientId: rows.clientId,
                    ProjectId: rows.projectId,
                    IsDelete: rows.isDelete || 0
                })),
            };
             URLS.saveExecutiveExpenses(payload)
                .then((response) => {
                    if (response.status === 200) {
                        /*  navigate('/dashboard/oamasterdetailspage', { replace: true }); */
                        navigate('/dashboard/master/executiveexpenses', { replace: true });
                        enqueueSnackbar("Executive Expense data saved successfully", { variant: 'success' });

                        
                    }
                })
                .catch(error => {
                    enqueueSnackbar("Data does not saved", { variant: 'error' });
                    /* handleError(error, enqueueSnackbar); */
                });
           
            console.log("payload>>>>>>>>>>>", payload)
        },
    });
    console.log("amount>>>>>>>>>>>>", amount)
    const { handleSubmit } = formik

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date of expense"
                                inputFormat="dd/MMM/yyyy" // Update the format to 'YYYY/MM/DD'
                                value={Moment(fromDateValue, 'YYYY/MM/DD').toDate()} // Set the date to '20/08/2024'
                                onChange={handleEffFrom}
                                disabled={executiveExpenses[0]?.Date }
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <FormControl>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedType}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Expense" disabled={isDisabled(1)} />
                                <FormControlLabel value={2} control={<Radio />} label="Receipt" disabled={isDisabled(2)} />
                                <FormControlLabel value={3} control={<Radio />} label="Return" disabled={isDisabled(3)} />
                            </RadioGroup>

                        </FormControl>
                    </Stack>
                    {selectedType === 1 && (
                        <>
                            <Scrollbar>
                            <ContentStyle>
                                    <ExecutiveExpensesDetailsTabPanel formik={formik} handleShow={handleShow} handleTabChangeValue={handleTabChangeValue} value={value} fieldSets={fieldSets}
                                        handleAllocationRadioChange={handleAllocationRadioChange} selectedAllocationType={selectedAllocationType} selectedClientId={selectedClientId}
                                        selectedClient={selectedClient} handleClientChange={handleClientChange} selectProject={selectProject} selectedProjectId={selectedProjectId}
                                        handleProjectChange={handleProjectChange} handleExpensesCategory={handleExpensesCategory} expensesCategory={expensesCategory}
                                        selectedexpensesCategory={selectedexpensesCategory} travelCategory={travelCategory} selectedeTravelCategory={selectedeTravelCategory}
                                        handleTravelCategory={handleTravelCategory} foodCategory={foodCategory} selectedeFoodCategory={selectedeFoodCategory} handleFoodCategory={handleFoodCategory}
                                        handleAllocationValueChange={handleAllocationValueChange} gridRows={gridRows} allocationValue={allocationValue} handleAddRow={handleAddRow}
                                        handleFieldChange={handleFieldChange} selectedExpenseCategoryId={selectedExpenseCategoryId} handleDelete={handleDelete} editingRowId={editingRowId}
                                        newAllocationValue={newAllocationValue} handleEdit={handleEdit} handleSave={handleSave} setNewAllocationValue={setNewAllocationValue}
                                        executiveExpenses={executiveExpenses} setGridRows={setGridRows }
                                />
                                </ContentStyle>
                            </Scrollbar>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
                                {value === 0 && (
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ color: 'white' }}
                                    onClick={handleNextClick }
                                >
                                    Next
                                </Button>
                                )}
                                {value === 1 && (
                                    <>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            sx={{ color: 'white' }}
                                            onClick={handlePreviousClick}

                                        >
                                            Previous
                                        </Button>
                                        <LoadingButton
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            sx={{ color: 'white' }}
                                            
                                        >
                                            Save
                                        </LoadingButton>
                                      
                                       
                                    </>
                                 
                                )}

                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={handelCancelClick}

                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </>
                       
                    )}
                    {(selectedType === 2 || selectedType === 3)  && (
                        <>
                            <Stack spacing={4} style={{ marginTop:'20px' }}>
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={4} >
                                <TextField
                                    fullWidth
                                    size="small"
                                        label="Amount"
                                        type="number"
                                        value={amount}
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            setAmount(value);
                                            formik.setFieldValue('Amount', value);  // Update Formik's state
                                        }}
                                       
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                        label="Remark"
                                        value={remark}
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            setRemark(value);
                                            formik.setFieldValue('Remark', value);  // Update Formik's state
                                        }}
                                        onBlur={() => formik.setFieldTouched('Remark', true)}  
                                        error={formik.touched.Remark && Boolean(formik.errors.Remark)}
                                        helperText={formik.touched.Remark && formik.errors.Remark}
                                />
                            </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row', }} justifyContent="center" spacing={4} >
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{ color: 'white' }}

                                    >
                                        Save
                                    </LoadingButton>
                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={handelCancelClick}

                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                </Stack>
                            </Stack>
                        </>

                    )}
                </Stack>
                
             
            </Form>

            { openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} /> }

        </FormikProvider>

               
    );
}