import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function FillInstallationReport() {
    const { enqueueSnackbar } = useSnackbar();

    const ClientLocationDropDown = [
        { Id: 1, value: 'Pending' },
        { Id: 2, value: 'Completed' },
        { Id: 3, value: 'Cancelled' }
    ];

    const TABLE_HEAD = [
        { field: 'ClientName', headerName: 'Client Name', width: 180 },
        { field: 'ProductName', headerName: 'Product', width: 180 },
        { field: 'InstallationStatusUpdateDateTime', headerName: 'Installation Date', width: 180 },
        { field: 'IssueId', headerName: 'Issue Id', width: 180 },

         {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
             renderCell: (cellValues) => (
                 <div>
                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                )
         },

    ];

    // dropdown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const [statusId, setStatusId] = useState('');
    // Dates 
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [issueId, setIssueId] = useState('');
    const [editData, setEditData] = useState([])

    const location = useLocation();
    const navigate = useNavigate();

    const handleClient = (id) => {
        setSelectedClientId(id);

    };

    const handleStatus = (id) => {
        setStatusId(id);
    };

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    useEffect(() => {
        URLS.getClientLandingPageGenerateReportUI()
            .then((response) => {

                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);

    const handleClick = (event, cellValues) => {
        console.log(cellValues.row.Id,"cellValues")
        setIsLoading(true);
        const payload = { InstallationReportQuestionnairesMasterId: cellValues.row.Id }
        URLS.fillInstallationReportFilterData(payload)
            .then((response) => {
                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                console.log("modifiedData", modifiedData)
                setEditData(modifiedData);
                navigate('/dashboard/fillinstallationreportdetailspage', { state: { fillInstallation: modifiedData } })

            })
            .catch(error => {
                // On error
                enqueueSnackbar("Record not found", { variant: 'success' });
                setIsLoading(false);

            });
     }
     console.log("reports",reports)
    const handleIssueIdChnage = (e) => {
        setIssueId(e.target.value)
    }

    const onFilterClick = () => {
        setIsLoading(true);
        const formattedDate = format(effectiveFromValue, 'dd-MMM-yyyy');

        const payload = { ClientId: selectedClientId, FromDate: effectiveFromValue, ToDate: effectiveToValue, IssueId:issueId }
        URLS.installationReportFilterData(payload)
            .then((response) => {
                setReports(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                // On error
                enqueueSnackbar("Record not found", { variant: 'success' });
                setIsLoading(false);

            });
    };

    const handleReset = () => {
        setReports([]);
        setSelectedClientId('');
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setStatusId('');
        setIssueId('')
    }

    useEffect(() => {
        setSelectedClientId('');
        setReports([]);
        setStatusId('');
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setIssueId('')

    }, [location.key])


    return (
        <Page title="Installation Report">
            <Card>
                <Stack direction="column" m={1} spacing={2}>
                    {/* First row: Client, Status, and Issue Id */}
                    <Stack direction="row" spacing={2}>
                        <FormControl
                            size="small"
                            sx={{ flexGrow: 1 }}
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Clients</InputLabel>
                            <Select
                                label="Clients"
                                size="small"
                                value={selectedClientId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""}>Select</MenuItem>
                                {clients.map((product) => (
                                    <MenuItem key={product.Id} value={product.Id} onClick={() => handleClient(product.Id)}>
                                        {product.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl
                            size="small"
                            sx={{ flexGrow: 1 }}
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Status</InputLabel>
                            <Select
                                label="Status"
                                value={statusId}
                                MenuProps={{
                                    style: { maxHeight: '200px', width: '200px' }
                                }}
                            >
                                <MenuItem key={0} value={""}>Select</MenuItem>
                                {ClientLocationDropDown.map((ofc) => (
                                    <MenuItem key={ofc.Id} value={ofc.Id} onClick={() => handleStatus(ofc.Id)}>
                                        {ofc.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            size="small"
                            label="Issue Id"
                            value={issueId}
                            onChange={handleIssueIdChnage }
                            sx={{ flexGrow: 1 ,width:'20px'}}
                        />
                    </Stack>

                    {/* Second row: From Date, To Date, and buttons */}
                    <Stack direction="row" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} sx={{ flexGrow: 1 }} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                onChange={handleEffTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} sx={{ flexGrow: 1 }} />}
                            />
                        </LocalizationProvider>

                        {/* Action Buttons: Filter, Add, Reset */}
                        <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
                            <Tooltip title="Filter">
                                <Button variant="contained" size="small" onClick={onFilterClick}>
                                    <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Add New">
                                <Button
                                    component={RouterLink}
                                    to='/dashboard/fillinstallationreportdetailspage'
                                    variant="contained"
                                    size="small"
                                    state={{
                                        fillInstallation: {
                                            Id: '', ClientId: '', ProductId: '', SiteId: '',
                                            InstallationStatus: '', InstallationStatusDate: '',
                                            InstallationRemark: ''
                                        }
                                    }}
                                >
                                    <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Reset">
                                <Button variant="contained" size="small" onClick={handleReset}>
                                    <Icon icon="ic:twotone-restart-alt" width="25px" height="25px" />
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Stack>

                <Divider />

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        density={'compact'}
                        columns={TABLE_HEAD}
                        getRowId={(row) => reports.indexOf(row)}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    />
                </div>

            </Card>

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}
        </Page>
    );


}