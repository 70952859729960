import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Menu,
    Fade,
    Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import AppCache from '../../../services/AppCache';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
const reportTypeData = [
    { id: 1, Name: 'Detailed' },
    { id: 2, Name: 'Summary' },
];

export default function Report() {

    const summaryColumns = [
        {
            field: 'Date',
            headerName: 'Date',
            width: 150,
            renderCell: (params) => (
                <span>{params.value}</span>
            )
        },
        { field: 'Expense', headerName: 'Expense', width: 100 },
        { field: 'Received', headerName: 'Received', width: 100 },
        { field: 'Returned', headerName: 'Return', width: 100 }
    ];

    const detailedColumns = [
        {
        field: 'Date',
        headerName: 'Date',
        width: 100,
        valueFormatter: (params) => params?.value ? Moment(params.value).format("DD/MM/YYYY") : ""
    },
        { field: 'TypeName', headerName: 'Type', width: 100 },
        { field: 'ExpenseCategoryName', headerName: 'Expense Category', width: 130 },
        { field: 'ProjectName', headerName: 'Project', width: 130 },
        { field: 'ClientName', headerName: 'Client', width: 130 },
        { field: 'Amount', headerName: 'Expense', width: 100 },
        {
            field: 'Received',
            headerName: 'Received',
            width: 100,
           
        },
        {
            field: 'Return',
            headerName: 'Return',
            width: 100,
        },
        { field: 'RunningBalance', headerName: 'Balance', width: 100 },
        { field: 'DayExpense', headerName: 'Day Expense', width: 100 },
        {
            field: 'Description',
            headerName: 'Description',
            width: 200,
            // renderCell: (params) => {
            //    const { TravelModeName, FromLocation, ToLocation, Count, ExpenseCategoryName, HotelName, City, FoodCategoryName } = params.row;

            //    if (ExpenseCategoryName === "Travelling") {
            //        return `${TravelModeName} from ${FromLocation || 'Hotel President'} to ${ToLocation || 'Office'} with ${Count > 0 ? Count : 'N/A'}`;
            //    }

            //    if (ExpenseCategoryName === "Travel Allowance") {
            //        return City ? `${City}` : '';
            //    }

            //    if (ExpenseCategoryName === "Toll Charges") {
            //        const passengerCount = Count > 0 ? `at ${Count}` : '';
            //        return `Toll from ${FromLocation || 'Mumbai'} to ${ToLocation || 'Pune'} ${passengerCount}`.trim();
            //    }

            //    if (ExpenseCategoryName === "Food") {
            //        const peopleCount = Count > 0 ? `for ${Count}` : '';
            //        return `${FoodCategoryName || 'Dinner'} at ${HotelName || 'Fish Curry Rice'} ${peopleCount}`.trim();
            //    }

            //    return ''; // Return empty if no matching category
            // }
        },
        { field: 'Remark', headerName: 'Remarks', width: 150 },
    ]

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    // Report type DropDown
    const [reportType, setReportType] = useState(1);
    const [defaultReportType, setDefaultReportType] = useState(1);
    const [column, setColumns] = useState(detailedColumns);

    // Executive DropDown
    const [executive, setExecutive] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState('');

    // Dates
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(new Date());

    // Report 
    const [reports, setReports] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState();

    const [userId, setUserId] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
   
    // Client dropdown
    const handleClient = (id) => {
        setSelectedExecutive(id);
    };

    // Dates 
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };
    const [isVisible, setIsVisible] = useState(false);

    const columns = [];
    const rows = [];

    reports.forEach((item, index) => {
        const row = { id: index + 1 };
        Object.entries(item).forEach(([key, value]) => {
            if (index === 0) {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 150
                });
            }
            row[key] = value;
        });
        rows.push(row);
    });

    console.log("Columns:", columns);
    console.log("Rows:", rows);

    // Filter
    const onGenerateClick = () => {
       
        // Validate reportType before proceeding

        const formattedFromDate = Moment(effectiveFromValue).format('YYYY/MM/DD');
        const formattedToDate = Moment(effectiveToValue).format('YYYY/MM/DD');
        const payload = { ExpenseMasterId: selectedExecutive || userId, FromDate: formattedFromDate, ToDate: formattedToDate, ReportType: reportType };
        const columnsToUse = reportType === 1 ? detailedColumns : summaryColumns ;
        setColumns(columnsToUse);
        setIsLoading(true);

        console.log("Selected reportType:", reportType);
            URLS.getExecutiveReportData(payload)
                .then((response) => {

                    const updatedReports = response.data.map((entry, index) => {
                        if (index === 0) {
                            return { ...entry, RunningBalance: entry.OpeningBalance || 0 };
                        }
                        return entry;
                    });
                    setReports(updatedReports);
                    enqueueSnackbar("Fetch data successfully", { variant: 'success' });
                    setIsLoading(false);
                })
                .catch(error => {
                    setReports([]); 
                    setIsLoading(false);
                });
        
    };


    // Reset
    const onResetClick = () => {
        setSelectedExecutive("");
        setReportType(1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(new Date());
        setReports([]);
    }

    // Status DropDown
    const handleReportTypeChange = (event) => {
        const newReportType = event.target.value;
        setReportType(newReportType);
    };
    const handleSelectedStatus = (id) => {
        setDefaultReportType(id);
        setReportType(id);

    };
    const handleExport = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fileExtention = '.xlsx';

    const FileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Select the current columns and data based on report type
        const columnsToUse = reportType === 1 ? detailedColumns : summaryColumns  ;
        const filteredData = reports.map((entry) => {
            const filteredEntry = {};
            columnsToUse.forEach((column) => {
                let value = entry[column.field];

                // Apply conditional formatting for specific fields and report types
                if (reportType === 1) {
                    if (column.field === 'Description') {
                        // Handle description logic for detailed report
                        const { TravelModeName, FromLocation, ToLocation, Count, ExpenseCategoryName, HotelName, City, FoodCategoryName } = entry;

                        if (ExpenseCategoryName === "Travelling") {
                            value = `${TravelModeName || ''} from ${FromLocation || 'Hotel President'} to ${ToLocation || 'Office'} with ${Count || 'N/A'}`;
                        } else if (ExpenseCategoryName === "Travel Allowance") {
                            value = City || '';
                        } else if (ExpenseCategoryName === "Toll Charges") {
                            const passengerCount = Count ? `at ${Count}` : '';
                            value = `Toll from ${FromLocation || 'Mumbai'} to ${ToLocation || 'Pune'} ${passengerCount}`.trim();
                        } else if (ExpenseCategoryName === "Food") {
                            const peopleCount = Count ? `for ${Count}` : '';
                            value = `${FoodCategoryName || 'Dinner'} at ${HotelName || 'Fish Curry Rice'} ${peopleCount}`.trim();
                        } else {
                            value = '';
                        }
                    }
                }

                filteredEntry[column.field] = value !== undefined ? value : ''; // Assign value or empty string if undefined
            });

            return filteredEntry;
        });

        // Create Excel sheet from filtered data
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

        // Set column widths dynamically
        ws['!cols'] = columnsToUse.map((col) => ({ wpx: col.width }));

        // Generate the Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        // Save the file with a dynamic name
        const fileName = `ExecutiveExpensesReport_${reportType === 1 ? 'Detailed' : 'Summary'}${fileExtension}`;
        FileSaver.saveAs(data, fileName);
    };


    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    // Client dropdown api call
    useEffect(() => {
        URLS.getExecutivesDropDown()
            .then((response) => {
                setExecutive(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        
        
    }, []);
    console.log("userId >>>>>>>",userId)
    useEffect(() => {
        // Ensure userId is set from the logged-in user
        const currentUserId = CurrentLoggedInUser?.ExecutiveId;
        if (currentUserId) {
            setUserId(currentUserId);
        }

        // Fetch executives for the dropdown
        URLS.getExecutivesDropDown()
            .then((response) => {
                setExecutive(response.data);
            })
            .catch(error => {
                console.log("Error fetching executives", error);
            });

        // Fetch executive report viewer ID based on userId
        if (userId) {
            const payload = { ExpenseMasterId: userId };
            URLS.executiveExpenseReportViewerId(payload)
                .then((response) => {
                    if (response.status === 200) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                })
                .catch(error => {
                    setIsVisible(false);
                    console.log("Error fetching executive report viewer ID", error);
                });
        }
    }, [userId]);  // Add userId to the dependency array



    useEffect(() => {
        setSelectedExecutive("");
        setReportType(1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(new Date());
        setReports([]);
    }, [location.key]);

    return (

        <Page title="Report" >
            <Typography variant="h5" color="#b71c1c" margin={2} >
                  Expenses Report
            </Typography>
            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Report Type
                        </InputLabel>
                        <Select
                            label="Report Type"
                            size="small"
                            value={reportType}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                    },
                                },
                            }}

                            onChange={handleReportTypeChange}
                        >

                            {reportTypeData.map((category) => (
                                <MenuItem
                                    key={category.id}
                                    value={category.id}
                                >
                                    {category.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('YYYY/MM/DD')}
                            onChange={handleEffFrom}
                            maxDate={new Date()} 
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('YYYY/MM/DD')}
                            onChange={handleEffTo}
                            disableFuture
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    {isVisible && (
                        <>
                            <FormControl
                                size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Executive</InputLabel>
                                <Select
                                    label="Executive"
                                    size="small"
                                    value={selectedExecutive}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                                width: '200px',// Set the max height here
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem key={0} value={""} onClick={() => handleClient()} >Select</MenuItem>

                                    {executive.map((product) => (
                                        <MenuItem key={product.Id} value={product.Id} onClick={() => handleClient(product.Id)}> {product.Name}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                           
                        </>
                    )}
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onGenerateClick}

                            >Generate</Button>
                        </Tooltip >
                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleExport} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export to excel</Button></MenuItem>


                        </Menu>

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={reports} density={'compact'} columns={column} getRowId={(row) => reports.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}